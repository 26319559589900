import React from 'react';
import Galeria from "../../Galeria";

import label from './img/ESCULTURA.png'

import img1 from "./img/1. N25. Superficie sólida. 31 x 65 x 10 cm. 2021 copy.png"
import img2 from "./img/2. Colgante N1. Superficie sólida. 120 x 45 x 20 cm. 2020 copy.png"
import img3 from "./img/3. B14, Superficie sólida, 31 x 58 x 32cm, 2020 copy.png"
import img4 from "./img/4. Pétalos, Resina epóxica sobre hierro, 60 x 65 x 54 cm, 2020 copy.png"
import img5 from "./img/5. La vida en azul, Resina cristal y metal, 37x50 cm, 2020 copy.png"
import img6 from "./img/6. G6.Superficie sólida. 34 x 50 x 23 cm. 2021 copy.png"
import img7 from "./img/7. RojoCadena1, Superficie sólida, 91 x 20 x 16 cm, 2020 copy.png"
import img8 from "./img/8. Colgante M1, Superficie sólida, 85 x 60 x 20cm, 2020 copy.png"
import img9 from "./img/9. Rr7, Superficie sólida, 33 x 53 x 20 cm, 2020 copy.png"

const images = [
    {original: img1, description: "1. N25. Superficie sólida. 31 x 65 x 10 cm. 2021 copy.png"},
    {original: img2, description: "2. Colgante N1. Superficie sólida. 120 x 45 x 20 cm. 2020 copy.png"},
    {original: img3, description: "3. B14, Superficie sólida, 31 x 58 x 32cm, 2020 copy.png"},
    {original: img4, description: "4. Pétalos, Resina epóxica sobre hierro, 60 x 65 x 54 cm, 2020 copy.png"},
    {original: img5, description: "5. La vida en azul, Resina cristal y metal, 37x50 cm, 2020 copy.png"},
    {original: img6, description: "6. G6.Superficie sólida. 34 x 50 x 23 cm. 2021 copy.png"},
    {original: img7, description: "7. RojoCadena1, Superficie sólida, 91 x 20 x 16 cm, 2020 copy.png"},
    {original: img8, description: "8. Colgante M1, Superficie sólida, 85 x 60 x 20cm, 2020 copy.png"},
    {original: img9, description: "9. Rr7, Superficie sólida, 33 x 53 x 20 cm, 2020 copy.png"},
];

const Escultura = () => <Galeria images={images} label={label}/>;

export default Escultura;


