import React from 'react';
import Galeria from "../../Galeria";

import label from './img/NUCLEOS.png'


import img1 from "./img/1. Avanzar, Mixta en tela, 100 x 100 cm, 2016.jpg copy.png"
import img2 from "./img/2. Bajo la lupa, Mixta sobre madera, 100 x 100 cm, 2011 copy.png"
import img3 from "./img/3. Brotar, Esmalte y óleo en tela, 185 x 140 cm, 2016 copy.png"
import img4 from "./img/4. Conjunción, Mixtas en tela, 100 x 100 cm, 2018 copy.png"
import img5 from "./img/5. En calma, Acrílico en tela, 190 x 190 cm, 2014 copy.png"
import img6 from "./img/6. Entre líneas1, Mixta en tela, 100 x 100 cm, 2019 copy.png"
import img7 from "./img/7. Entre líneas2, Mixta en tela, 100 x 100 cm, 2019 copy.png"
import img8 from "./img/8. Ignición, Mixta en tela, 150 x 150 cm, 2017 copy.png"
import img9 from "./img/9. La creación, Mixta en tela, 140 x 180 cm, 2015 copy.png"
import img10 from "./img/10. Mapeo, Mixta en tela, 140 x 140 cm, 2017 copy.png"
import img11 from "./img/11. Proceso de apertura, Mixta en tela, 150 x 140 cm, 2016  copy.png"
import img12 from "./img/12. Resurgir, Mixta en cartón pluma, 50 x 70 cm, 2011 copy.png"
import img13 from "./img/13. Ritmos, Óleo en tela, 140 x 180 cm, 2020 copy.png"
import img14 from "./img/14. Te miro y me miro, Óleo y barniz en tela,100 x 100 cm, 2018 copy.png"
import img15 from "./img/15. Vibrando el ahora, Mixta en tela, 150 x 300 cm, 2017 copy.png"

const images = [
    {original: img1, description: "1. Avanzar, Mixta en tela, 100 x 100 cm, 2016.jpg copy.png"},
    {original: img2, description: "2. Bajo la lupa, Mixta sobre madera, 100 x 100 cm, 2011 copy.png"},
    {original: img3, description: "3. Brotar, Esmalte y óleo en tela, 185 x 140 cm, 2016 copy.png"},
    {original: img4, description: "4. Conjunción, Mixtas en tela, 100 x 100 cm, 2018 copy.png"},
    {original: img5, description: "5. En calma, Acrílico en tela, 190 x 190 cm, 2014 copy.png"},
    {original: img6, description: "6. Entre líneas1, Mixta en tela, 100 x 100 cm, 2019 copy.png"},
    {original: img7, description: "7. Entre líneas2, Mixta en tela, 100 x 100 cm, 2019 copy.png"},
    {original: img8, description: "8. Ignición, Mixta en tela, 150 x 150 cm, 2017 copy.png"},
    {original: img9, description: "9. La creación, Mixta en tela, 140 x 180 cm, 2015 copy.png"},
    {original: img10, description: "10. Mapeo, Mixta en tela, 140 x 140 cm, 2017 copy.png"},
    {original: img11, description: "11. Proceso de apertura, Mixta en tela, 150 x 140 cm, 2016  copy.png"},
    {original: img12, description: "12. Resurgir, Mixta en cartón pluma, 50 x 70 cm, 2011 copy.png"},
    {original: img13, description: "13. Ritmos, Óleo en tela, 140 x 180 cm, 2020 copy.png"},
    {original: img14, description: "14. Te miro y me miro, Óleo y barniz en tela,100 x 100 cm, 2018 copy.png"},
    {original: img15, description: "15. Vibrando el ahora, Mixta en tela, 150 x 300 cm, 2017 copy.png"},
];

const Nucleos = () => <Galeria images={images} label={label}/>;

export default Nucleos;




