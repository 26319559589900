import React from "react";
// import './Textos.css'
import alfonso_text from './material/TSala1Alfonso Villarreal,CVeracruzano.pdf';
import alfonso_img from './material/Botones/alfonso boton.png';
import cv_text from './material/Cv Mgazine.pdf';
import cv_img from './material/Botones/magazine boton.png';
import habitus_text from './material/Texto de sala Habitus1Edición.pdf';
import habitus_img from './material/Botones/habitus boton.png';
import superficie_text from './material/SUPERFICIE_No.12_.pdf';
import superficie_img from './material/Botones/siperficie boton.png';
import revistart_text from './material/Revisart_27-A.jpg';
import revistart_img from './material/Botones/revistart boton.png';
import susana_text from './material/La arteinformada.Curadora Susana López Fernandez.Arteinformado.expoOnline.2019.pdf';
import susana_img from './material/Botones/siperficie boton copia.png';
import Modal from "react-modal";
import {LanguageSelector} from "../../pdfs/PDFViewer";
import {FaTimesCircle} from "react-icons/fa";

class Texto extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: false
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal() {
        this.setState({modalIsOpen: true});
    }

    closeModal(e) {
        e.stopPropagation();
        this.setState({modalIsOpen: false});
    }

    render() {

        // alert(this.props.img);
        return <div onClick={this.openModal}>
            <img className="TextoCover" src={this.props.img} alt=""/>
            {
                this.props.url &&
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={{
                        overlay: {zIndex: 999999},
                        content: {
                            top: '20px',
                            left: '20px',
                            right: '20px',
                            bottom: '20px',
                            backgroundColor: 'lightgrey'
                        }
                    }}
                    // contentLabel={this.props.title}
                    ariaHideApp={false}
                >
                    {/*<h2 className="ModalTitle">{this.props.title}</h2>*/}
                    <FaTimesCircle onClick={this.closeModal} className="CloseButton"/>
                    <LanguageSelector spanishUrl={this.props.url}/>
                </Modal>
            }
        </div>;
    }
}

function TextosGrid() {
    return <div className="TextosGrid">
        <Texto url={alfonso_text} img={alfonso_img}/>
        <Texto url={cv_text} img={cv_img}/>
        <Texto url={habitus_text} img={habitus_img}/>
        <Texto url={superficie_text} img={superficie_img}/>
        <Texto url={revistart_text} img={revistart_img}/>
        <Texto url={susana_text} img={susana_img}/>
    </div>;
}

function Textos() {
    return <TextosGrid/>
}

export default Textos;

