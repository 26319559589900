import React from 'react';
import Galeria from "../../Galeria";

import label from './img/DESTELLOS.png' 

import img1 from "./img/1. Primavera, Resina cristal, 65 x 70 cm aproximado, 2020 copy.png"
import img2 from "./img/2. Cilindro 1, Resina cristal y acrílico, 65 x 45 x 8 cm, 2020 copy.png"
import img3 from "./img/3. Cilindro 2, Resina cristal y acrílico, 59 x 76 x 8 cm, 2020 copy.png"
import img4 from "./img/4. NO,  Resina cristal sobre lámina de acrílico pintada con óleo sobre resina, 95 x 82 cm, 2020 copy.png"
import img5 from "./img/5. Domino azul, Óleo y resina sobre lámina acrílico, 100x 66 x 4 cm, 2020 copy.png"
import img6 from "./img/6. Transparencias, Resina epóxica sobre lámina acrilico, 97 x 160 x 10 cm, 2020 copy.png"

const images = [
    {original: img1, description: "1. Primavera, Resina cristal, 65 x 70 cm aproximado, 2020 copy.png"},
    {original: img2, description: "2. Cilindro 1, Resina cristal y acrílico, 65 x 45 x 8 cm, 2020 copy.png"},
    {original: img3, description: "3. Cilindro 2, Resina cristal y acrílico, 59 x 76 x 8 cm, 2020 copy.png"},
    {original: img4, description: "4. NO,  Resina cristal sobre lámina de acrílico pintada con óleo sobre resina, 95 x 82 cm, 2020 copy.png"},
    {original: img5, description: "5. Domino azul, Óleo y resina sobre lámina acrílico, 100x 66 x 4 cm, 2020 copy.png"},
    {original: img6, description: "6. Transparencias, Resina epóxica sobre lámina acrilico, 97 x 160 x 10 cm, 2020 copy.png"},
];

const Destellos = () => <Galeria images={images} label={label}/>;

export default Destellos;


