import React from 'react';
import Galeria from "../../Galeria";

import label from './img/OXIDACION.png'


import img1 from "./img/1. Bruma, Mixta sobre lámina cobre y madera, 50 x 70 cm, 2011 copy.png"
import img2 from "./img/2. Cenizas, Óxidos_hierro, 12 x 45 cm, 2012 copy.png"
import img3 from "./img/3. Atrapada, Mixta en tela, 100 x 100 cm, 2018 copy.png"
import img4 from "./img/4. Tierras, Mixta sobre hierro, 53 x 35 cm, 2013 copy.png"
import img5 from "./img/5. Reacción, Lámina hierro y papel sobre madera, 90 x 160 cm, 2011 copy.png"
import img6 from "./img/6. Desintegración, Láminas de hierro, espejos, óxidos y pigmentos, 122 x 244 cm, 2017 copy.png"
import img7 from "./img/7. Abandono, Óxido sobre lámina de hierro, 122 x 122 cm, 2019 copy.png"
import img8 from "./img/8. Intromisión, Mixta sobre madera, 95 x 120 cm, 2012 copy.png"
import img9 from "./img/9. Vibrando el ahora, Esmalte, barniz y lámina oxidada sobre madera, 60 x 90 cm, 2012 copy.png"
import img10 from "./img/10. B2, Superficie sólida, 20 x 33 x 20 cm, 2019 copy.png"

const images = [
    {original: img1, description: "1. Bruma, Mixta sobre lámina cobre y madera, 50 x 70 cm, 2011 copy.png"},
    {original: img2, description: "2. Cenizas, Óxidos_hierro, 12 x 45 cm, 2012 copy.png"},
    {original: img3, description: "3. Atrapada, Mixta en tela, 100 x 100 cm, 2018 copy.png"},
    {original: img4, description: "4. Tierras, Mixta sobre hierro, 53 x 35 cm, 2013 copy.png"},
    {original: img5, description: "5. Reacción, Lámina hierro y papel sobre madera, 90 x 160 cm, 2011 copy.png"},
    {original: img6, description: "6. Desintegración, Láminas de hierro, espejos, óxidos y pigmentos, 122 x 244 cm, 2017 copy.png"},
    {original: img7, description: "7. Abandono, Óxido sobre lámina de hierro, 122 x 122 cm, 2019 copy.png"},
    {original: img8, description: "8. Intromisión, Mixta sobre madera, 95 x 120 cm, 2012 copy.png"},
    {original: img9, description: "9. Vibrando el ahora, Esmalte, barniz y lámina oxidada sobre madera, 60 x 90 cm, 2012 copy.png"},
    {original: img10, description: "10. B2, Superficie sólida, 20 x 33 x 20 cm, 2019 copy.png"},
];

const Oxidacion = () => <Galeria images={images} label={label}/>;

export default Oxidacion;


