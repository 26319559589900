import React from 'react';
// import './Bio.css'
import BioImg from '../img/bio/perfil_leonor.png'
import semblanza from '../files/bio/SEMBLANZA.pdf'
import semblance from '../files/bio/Inglés/SEMBLANCE.pdf'
import declaracion from '../files/bio/DECLARACIÓN.pdf'
import self from '../files/bio/Inglés/SELF.pdf'
import cv from '../files/bio/CV.pdf'
import resume from '../files/bio/Inglés/RESUME.pdf'
import publicaciones from '../files/bio/PUBLICACIONES.pdf'
import publications from '../files/bio/Inglés/PUBLICATIONS.pdf'
import Modal from "react-modal";
import {LanguageSelector} from "../pdfs/PDFViewer";
import {FaTimesCircle} from 'react-icons/fa';
import {NavLink} from "react-router-dom";
import Route from "react-router-dom/es/Route";
import Switch from "react-router-dom/es/Switch";
import Textos from './textos/Textos'

class BioItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: false
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal() {
        this.setState({modalIsOpen: true});
    }

    closeModal(e) {
        e.stopPropagation();
        this.setState({modalIsOpen: false});
    }

    render() {
        if (this.props.url) {
            return <NavLink to={this.props.url} className="BioItem" style={{backgroundImage: `url(${this.props.img})`}}>
                <div>
                    {this.props.title}
                </div>
            </NavLink>;
        } else {
            return (
                <div className="BioItem" style={{backgroundImage: `url(${this.props.img})`}} onClick={this.openModal}>
                    <div>
                        {this.props.title}
                        {
                            this.props.spanishUrl &&
                            <Modal
                                isOpen={this.state.modalIsOpen}
                                onRequestClose={this.closeModal}
                                style={{
                                    overlay: {zIndex: 999999},
                                    content: {
                                        top: '20px',
                                        left: '20px',
                                        right: '20px',
                                        bottom: '20px',
                                        backgroundColor: 'lightgrey'
                                    }
                                }}
                                contentLabel={this.props.title}
                                ariaHideApp={false}
                            >
                                <h2 className="ModalTitle">{this.props.title}</h2>
                                <FaTimesCircle onClick={this.closeModal} className="CloseButton"/>
                                <LanguageSelector spanishUrl={this.props.spanishUrl}
                                                  englishUrl={this.props.englishUrl}/>
                            </Modal>
                        }
                    </div>
                </div>
            );
        }
    }
}

function Bio(props) {
    return <Switch>
        <Route exact path="/bio" render={() =>
            <div className="Bio">
                <BioItem title="" img={BioImg}/>
                <BioItem title="Declaración" spanishUrl={declaracion} englishUrl={self}/>
                <BioItem title="Semblanza" spanishUrl={semblanza} englishUrl={semblance}/>
                <BioItem title="C.V." spanishUrl={cv} englishUrl={resume}/>
                <BioItem title="Textos" url={`${props.match.url}/textos`}/>
                <BioItem title="Publicaciones" spanishUrl={publicaciones} englishUrl={publications}/>
            </div>
        }/>
        <Route path="/bio/textos" render={() =>
            <Textos/>
        }/>
    </Switch>;
}

export default Bio;
