import React from 'react';
import Galeria from "../../Galeria";

import label from './img/OSCURIDAD.png'

import img1 from "./img/1. Muro, Resina cristal y tela, 240 x 180 cm, 2020 copy.png"
import img2 from "./img/2. Punzada, Mixta en tela, 80 x 180 cm, 2019 copy.png"
import img3 from "./img/3. N8, Superficie Sólida, 37 x 87 x 55 cm, 2019 copy.png"
import img4 from "./img/4. G14. Superficie sólida. 23 x 43 x 18 cm. 2021 copy.png"
import img5 from "./img/5. El ayer 1, Resina, rama de árbol y lino sobre madera, 122 x 94 cm, 2019 copy.png"
import img6 from "./img/6. El ayer 2, Resina, rama de árbol y lino sobre madera, 122 x 94cm, 2019 copy.png"
import img7 from "./img/7. Hojas, Óleo, resina, ramas, hojas árbol sobre lámina de acrílico, 120 x 90cm, 2020 copy.png"
const images = [
    {original: img1, description: "1. Muro, Resina cristal y tela, 240 x 180 cm, 2020 copy.png"},
    {original: img2, description: "2. Punzada, Mixta en tela, 80 x 180 cm, 2019 copy.png"},
    {original: img3, description: "3. N8, Superficie Sólida, 37 x 87 x 55 cm, 2019 copy.png"},
    {original: img4, description: "4. G14. Superficie sólida. 23 x 43 x 18 cm. 2021 copy.png"},
    {original: img5, description: "5. El ayer 1, Resina, rama de árbol y lino sobre madera, 122 x 94 cm, 2019 copy.png"},
    {original: img6, description: "6. El ayer 2, Resina, rama de árbol y lino sobre madera, 122 x 94cm, 2019 copy.png"},
    {original: img7, description: "7. Hojas, Óleo, resina, ramas, hojas árbol sobre lámina de acrílico, 120 x 90cm, 2020 copy.png"},
];

const Obscuridad = () => <Galeria images={images} label={label}/>;

export default Obscuridad;



