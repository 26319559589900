import React from 'react';
import {NavLink, Route} from "react-router-dom";
import Rocas from "./series/rocas/Rocas.js"
import Restos from "./series/restos/Restos.js"
import Refraccion from "./series/refraccion/Refraccion.js"
import Reflejos from "./series/reflejos/Reflejos.js"
import Oxidacion from "./series/oxidacion/Oxidacion.js"
import Obscuridad from "./series/obscuridad/Obscuridad.js"
import Nucleos from "./series/nucleos/Nucleos.js"
import Naturaleza from "./series/naturaleza/Naturaleza.js"
import Fragmentacion from "./series/fragmentacion/Fragmentacion.js"
import Escultura from "./series/escultura/Escultura.js"
import Destellos from "./series/destellos/Destellos.js"

const Serie = ({name, to}) =>
<div class="Serie">
    <NavLink to={to}>
        {name}
    </NavLink>
</div>;

function Obra({ match }) {
    return (
        <div>
            <Route exact path="/obra" render={
                () =>
                    <div className="Obra">
                        <Serie name="ROCAS" to={`${match.url}/rocas`} />
                        <Serie name="NÚCLEOS" to={`${match.url}/núcleos`} />
                        <Serie name="OXIDACIÓN" to={`${match.url}/oxidación`} />
                        <Serie name="ESCULTURA" to={`${match.url}/escultura`} />
                        <Serie name="REFRACCIÓN" to={`${match.url}/refracción`} />
                        <Serie name="FRAGMENTACIÓN" to={`${match.url}/fragmentación`} />
                        <Serie name="NATURALEZA" to={`${match.url}/naturaleza`} />
                        <Serie name="DESTELLOS" to={`${match.url}/destellos`} />
                        <Serie name="OSCURIDAD" to={`${match.url}/oscuridad`} />
                        <Serie name="REFLEJOS" to={`${match.url}/reflejos`} />
                        <Serie name="RESTOS" to={`${match.url}/restos`} />
                    </div>
            } />

            <Route path={`${match.url}/rocas`} component={Rocas} />
            <Route path={`${match.url}/restos`} component={Restos} />
            <Route path={`${match.url}/refracción`} component={Refraccion} />
            <Route path={`${match.url}/oxidación`} component={Oxidacion} />
            <Route path={`${match.url}/oscuridad`} component={Obscuridad} />
            <Route path={`${match.url}/reflejos`} component={Reflejos} />
            <Route path={`${match.url}/núcleos`} component={Nucleos} />
            <Route path={`${match.url}/naturaleza`} component={Naturaleza} />
            <Route path={`${match.url}/fragmentación`} component={Fragmentacion} />
            <Route path={`${match.url}/escultura`} component={Escultura} />
            <Route path={`${match.url}/destellos`} component={Destellos} />
        </div>
    );
}

export default Obra;
