import React from 'react';
import Galeria from "../../Galeria";

import label from './img/NATURALEZA.png'


import img1 from "./img/1. Atardecer. Óleo y acrilico. 100 x 200 cm. 2021 copy.png"
import img2 from "./img/2. Formaciones, Mixta en tela, 90 x 190 cm, 2018 copy.png"
import img3 from "./img/3. Escapando, Esmalte y óleo en tela, 60 x 80 cm, 2018 copy.png"
import img4 from "./img/4. Florecer, Mixta, óleo, esmalte, acrílico y papel s_madera, 160 x 80cm.2018 copy.png"
import img5 from "./img/5. Impresión, Mixta sobre madera, 76 x 107 cm, 2018 copy.png"
import img6 from "./img/6. En grises, Óleo y resina sobre madera, 94 x 120 cm, 2020 copy.png"
import img7 from "./img/7. Raíces del alma, Mixta en tela, 140 x 185 cm, 2016 copy.png"
import img8 from "./img/8. Inundando el alma, Mixta en tela, 190 x 240 cm, 2014 copy.png"

const images = [
    {original: img1, description: "1. Atardecer. Óleo y acrilico. 100 x 200 cm. 2021 copy.png"},
    {original: img2, description: "2. Formaciones, Mixta en tela, 90 x 190 cm, 2018 copy.png"},
    {original: img3, description: "3. Escapando, Esmalte y óleo en tela, 60 x 80 cm, 2018 copy.png"},
    {original: img4, description: "4. Florecer, Mixta, óleo, esmalte, acrílico y papel s_madera, 160 x 80cm.2018 copy.png"},
    {original: img5, description: "5. Impresión, Mixta sobre madera, 76 x 107 cm, 2018 copy.png"},
    {original: img6, description: "6. En grises, Óleo y resina sobre madera, 94 x 120 cm, 2020 copy.png"},
    {original: img7, description: "7. Raíces del alma, Mixta en tela, 140 x 185 cm, 2016 copy.png"},
    {original: img8, description: "8. Inundando el alma, Mixta en tela, 190 x 240 cm, 2014 copy.png"},
];

const Naturaleza = () => <Galeria images={images} label={label}/>;

export default Naturaleza;
