import React from 'react';

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const Galeria = ({label, images}) =>
    <div className="galleryTop">
        {
            label !== undefined &&
            <div className="LabelContainer">
                <img className="Label" src={label} alt="Materia"/>
            </div>
        }
        <div className="GalleryContainer">
            <ImageGallery items={images}
                          autoPlay={true}
                          showThumbnails={false}
                          slideDuration={500}
                          slideInterval={4500}
                          showFullscreenButton={true}
                          useBrowserFullscreen={false}
            />
        </div>
    </div>;

export default Galeria;
