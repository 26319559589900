import React from 'react';
// import './PDFViewer.css'
import {isMobile} from 'react-device-detect';

function PDFViewer(props) {

    if (isMobile) {
        return <div> <p>Tu navegador no tiene manera de mostrar PDFs de forma nativa.</p> 
     <a className="pdfLink" href={props.url}>Click aqui para descargar el archivo PDF.</a></div>
    }
    return <object data={props.url} type="application/pdf" width="100%" height="100%">
      <p>Tu navegador no tiene manera de mostrar PDFs de forma nativa.</p> 
      <a className="pdfLink" href={props.url}>Click aqui para descargar el archivo PDF.</a>
    </object>

//     return (
//     <object data={props.url} type="application/pdf" width="100%" height="100%">
//   <p>Tu navegador no tiene manera de mostrar PDFs de forma nativa.</p> 
//   <a className="pdfLink" href={props.url}>Click aqui para descargar el archivo PDF.</a>
// </object>)
}

class LanguageSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSpanish: null,
        };


        this.setSpanish = this.setSpanish.bind(this);
        this.setEnglish = this.setEnglish.bind(this);
    }

    setSpanish(e) {
        e.stopPropagation();
        this.setState({isSpanish: true});
    }

    setEnglish(e) {
        e.stopPropagation();
        this.setState({isSpanish: false});
    }

    render() {
        if (this.state.isSpanish === true) {
            return <PDFViewer url={this.props.spanishUrl}/>
        } else if (this.state.isSpanish === false) {
            return <PDFViewer url={this.props.englishUrl}/>
        } else {
            return <div className="LanguageTable">
                <div className="LanguageSelector center">
                    {
                        this.props.spanishUrl &&
                        <button className="LanguageButton" onClick={this.setSpanish} type="button">Español</button>
                    }
                    {
                        this.props.englishUrl &&
                        <button className="LanguageButton" onClick={this.setEnglish} type="button">English</button>
                    }
                </div>
            </div>
        }
    }
}

export {PDFViewer, LanguageSelector};