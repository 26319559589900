import React, {Component} from 'react';
import ReactImageVideoLightbox from 'react-image-video-lightbox';

class VideoLeonor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal() {
        this.setState({isOpen: true})
    }

    closeModal() {
        this.setState({isOpen: false});
    }

    render() {
        return (<div
            className="videoOverlay"
        >{
            this.state.isOpen &&
            <ReactImageVideoLightbox
                
                data={[
                    {
                        url: 'https://www.youtube.com/embed/fol-Vv3v_VM',
                        type: 'video',
                        altTag: 'Video de introducción'
                    },
                ]}
                startIndex={0}
                showResourceCount={false}
                onCloseCallback={this.closeModal}
            />
        }</div>);
    }
}

export default VideoLeonor;