import React from 'react';
import Galeria from "../../Galeria";

import label from './img/REFLEJOS.png'

import img1 from './img/1. En formación, Óleo, esmalte y barniz sobre lámina de acrílico y lienzo, 240 x 97 cm,2014 copy.png'
import img2 from './img/2. Hacia la luz, Óleo y barniz sobre lámina de acrílico y policarbonato,100 x 100 cm, 2013 copy.png'
import img3 from './img/3. Sensaciones, Óleo y barniz sobre lámina de acrílico y aluminio, 100 x 100 x 15 cm, 2014 copy.png'
import img4 from './img/4. Solución acuosa, Óleo, acrílico, barniz y resina  sobre lámina de acrílico y madera, 125 x 185 cm, 2014 copy.png'
import img5 from './img/5. Capas Interiores, Mixta sobre acrílico y papel, 28 x 36 cm, 2017 copy.png'
import img6 from './img/6. Ondas, Óleo y barniz sobre lámina de acrílico y papel, 28.5 x 21 cm, 2012 copy.png'
import img7 from './img/7. Intermitente, Óleo y resina sobre papel y latón, 37 x 64 cm, 2018 copy.png'
import img8 from './img/8. Compaginar 3, Óleo y resina cristal en tela, 100 x 120 cm, 2019 copy.png'
import img9 from './img/9. Estratos, Resina cristal y oleo sobre 2 láminas de acrílico, 94 x 120 x 6 cm, 2020 copy.png'
import img10 from './img/10. Rompiendo estructuras, Resina cristal y arena sílica sobre madera,122 x 94 cm, 2019 copy.png'
import img11 from './img/11. Dejar ir, Óleo, resina _ láminas de poliestireno y acrílico, 120 x 94, 2020cm copy.png'

const images = [
    {original: img1, description: "1. En formación, Óleo, esmalte y barniz sobre lámina de acrílico y lienzo, 240 x 97 cm,2014 copy.png"},
    {original: img2, description: "2. Hacia la luz, Óleo y barniz sobre lámina de acrílico y policarbonato,100 x 100 cm, 2013 copy.png"},
    {original: img3, description: "3. Sensaciones, Óleo y barniz sobre lámina de acrílico y aluminio, 100 x 100 x 15 cm, 2014 copy.png"},
    {original: img4, description: "4. Solución acuosa, Óleo, acrílico, barniz y resina  sobre lámina de acrílico y madera, 125 x 185 cm, 2014 copy.png"},
    {original: img5, description: "5. Capas Interiores, Mixta sobre acrílico y papel, 28 x 36 cm, 2017 copy.png"},
    {original: img6, description: "6. Ondas, Óleo y barniz sobre lámina de acrílico y papel, 28.5 x 21 cm, 2012 copy.png"},
    {original: img7, description: "7. Intermitente, Óleo y resina sobre papel y latón, 37 x 64 cm, 2018 copy.png"},
    {original: img8, description: "8. Compaginar 3, Óleo y resina cristal en tela, 100 x 120 cm, 2019 copy.png"},
    {original: img9, description: "9. Estratos, Resina cristal y oleo sobre 2 láminas de acrílico, 94 x 120 x 6 cm, 2020 copy.png"},
    {original: img10, description: "10. Rompiendo estructuras, Resina cristal y arena sílica sobre madera,122 x 94 cm, 2019 copy.png"},
    {original: img11, description: "11. Dejar ir, Óleo, resina _ láminas de poliestireno y acrílico, 120 x 94, 2020cm copy.png"},
];

const Reflejos = () => <Galeria images={images} label={label}/>;

export default Reflejos;

