import React, {Component} from 'react';
import {HashRouter, NavLink} from "react-router-dom";
import './App.scss';
import Nav1ImgWhite from './img/nav1-white.png'
import Nav1ImgBlack from './img/nav1.png'
import Nav2ImgWhite from './img/nav2-white.png'
import Nav2ImgBlack from './img/nav2.png'
import Nav3ImgWhite from './img/nav3-white.png'
import Nav3ImgBlack from './img/nav3.png'
import Switch from "react-router-dom/es/Switch";
import Route from "react-router-dom/es/Route";
import Obra from "./obra/Obra";
import Bio from "./bio/Bio";
import Contacto from "./contacto/Contacto";
import {faFacebook, faInstagram} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import VideoLeonor from "./video_leo/VideoLeonor";

function NavEntry(props) {
    const navLinkStyle = {
        backgroundImage: `url(${props.backgroundImage})`,
        color: props.color,
        transform: `translateY(${props.yTrans}) translateX(${props.xTrans})`,
    };

    return (
        <div className="wrapper" onClick={props.onClick}>
            <NavLink to={props.url}>
                <div className="NavCircle" style={{backgroundColor: props.bgColor}}/>
                <div className="NavLink" style={navLinkStyle}>
                    <div>{props.name}</div>
                </div>
            </NavLink>
        </div>
    );
}

class Nav extends Component {
    constructor(props) {
        super(props);
        this.state = {isHome: true};
        this.onClickWhenHome = props.onClickWhenHome;
    }

    setHome(value) {
        this.setState({isHome: value});
    }

    onClickWhenHome() {

    }

    getStyles() {
        return this.state.isHome ? {
            imgNav1: Nav1ImgWhite,
            imgNav2: Nav2ImgWhite,
            imgNav3: Nav3ImgWhite,
            primaryColor: "white",
            secondaryColor: "black",
            textShadow: "2px 2px 5px black"
        } : {
            imgNav1: Nav1ImgBlack,
            imgNav2: Nav2ImgBlack,
            imgNav3: Nav3ImgBlack,
            primaryColor: "black",
            secondaryColor: "white",
            textShadow: null
        };
    }


    render() {
        let navTitleStyle = {};

        // noinspection JSUnresolvedVariable
        if (this.props.titleColor) {
            navTitleStyle.color = this.props.titleColor;
        }

        return (
            <div className="Nav">
                <div className="flex-container">
                    <div className="NavTitle"
                         style={{color: this.getStyles().primaryColor}}
                         onClick={this.setHome.bind(this, true)}
                    >
                        {
                            !this.state.isHome &&
                            <NavLink to="/">
                                <span style={{textShadow: this.getStyles().textShadow}}>Leonor Hochschild</span>
                            </NavLink>
                        }
                        {
                            this.state.isHome &&
                            <span style={{textShadow: this.getStyles().textShadow, cursor: 'pointer'}}
                                  onClick={this.onClickWhenHome}>
                                Leonor Hochschild
                            </span>
                        }
                    </div>
                    <div className="HorizontalBar" style={{borderColor: this.getStyles().primaryColor}}/>
                    <div className="flex-container2">
                        <div>
                            <NavEntry name="obra"
                                      url="/obra"
                                      backgroundImage={this.getStyles().imgNav1}
                                      yTrans="-57px"
                                      xTrans="-50px"
                                      color={this.getStyles().secondaryColor}
                                      bgColor={this.getStyles().primaryColor}
                                      onClick={this.setHome.bind(this, false)}
                            />
                        </div>
                        <div>
                            <NavEntry name="bio"
                                      url="/bio"
                                      backgroundImage={this.getStyles().imgNav2}
                                      yTrans="-57px"
                                      xTrans="-50px"
                                      color={this.getStyles().secondaryColor}
                                      bgColor={this.getStyles().primaryColor}
                                      onClick={this.setHome.bind(this, false)}
                            />
                        </div>
                        <div>
                            <NavEntry name="contacto"
                                      url="/contacto"
                                      backgroundImage={this.getStyles().imgNav3}
                                      yTrans="-57px"
                                      xTrans="-40px"
                                      color={this.getStyles().secondaryColor}
                                      bgColor={this.getStyles().primaryColor}
                                      onClick={this.setHome.bind(this, false)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const BackgroundImage = () => <div className="BackgroundImage"/>;

class App extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.openModal = this.openModal.bind(this);
    }

    openModal() {
        this.myRef.current.openModal();
    }

    render() {
        return (
            <HashRouter>
                <div className="App">
                    <header>
                        <Nav onClickWhenHome={this.openModal}/>
                        <div className="Social">
                            <a href="https://www.facebook.com/leonorhochschild/">
                                <FontAwesomeIcon icon={faFacebook}/>
                            </a>
                            <a href="https://www.instagram.com/leonor_hochschild/">
                                <FontAwesomeIcon icon={faInstagram}/>
                            </a>
                        </div>
                    </header>
                    <Switch>
                        <Route exact path="/" render={() =>
                            <div>
                                <BackgroundImage/>
                                <VideoLeonor ref={this.myRef}/>
                            </div>
                        }/>
                        <Route path="/" render={() =>
                            <div>
                                <div className="mainContent">
                                    <Route exact path="/" component={BackgroundImage}/>
                                    <Route path="/obra" component={Obra}/>
                                    <Route path="/bio" component={Bio}/>
                                    <Route path="/contacto" component={Contacto}/>
                                </div>
                            </div>
                        }/>
                    </Switch>
                </div>
            </HashRouter>
        );
    }
}

export default App;
