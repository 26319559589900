import React from 'react';
import Galeria from "../../Galeria";

import label from './img/FRAGMENTACION.png'

import img1 from "./img/1. Alambre2, Mixta sobre madera, 120 x 90 cm, 2019 copy.png"
import img2 from "./img/2. Alambre11, Óleo, malla Alambre, yeso, gesso y cemento, 120 x 180 cm, 2019 copy.png"
import img3 from "./img/3. Alambre3, Mixta sobre madera, 122 x 94 cm, 2019 copy.png"
import img4 from "./img/4. Murmullo del silencio, Óleo y resina epóxica en tela, 140 x 180 cm, 2018 copy.png"
import img5 from "./img/5. Entrando, Óleo y resina en tela, 40 x 240cm c_u, díptico, 2018 copy.png"
import img6 from "./img/6. Sin fondo, Polvo mármol negro, óleo, malla alambre, yeso y cemento sobre madera, 122 x 94 cm, 2019  copy.png"
import img7 from "./img/7. Fracciones, Instalación, Resina epóxica y tela,  240 x 480 cm aproximado,2019 copy.png"
import img8 from "./img/8. Procesos, Esmalte y oleo en tela, 140 X 180 cm.2020 copy.png"
import img9 from "./img/9. Nódulos, Mixta en tela, 80 x 180 cm, 2018 copy.png"
import img10 from "./img/10. Lagrimas. Resina epóxica, 110 x 280 cm,2019 copy.png"

const images = [
    {original: img1, description: "1. Alambre2, Mixta sobre madera, 120 x 90 cm, 2019 copy.png"},
    {original: img2, description: "2. Alambre11, Óleo, malla Alambre, yeso, gesso y cemento, 120 x 180 cm, 2019 copy.png"},
    {original: img3, description: "3. Alambre3, Mixta sobre madera, 122 x 94 cm, 2019 copy.png"},
    {original: img4, description: "4. Murmullo del silencio, Óleo y resina epóxica en tela, 140 x 180 cm, 2018 copy.png"},
    {original: img5, description: "5. Entrando, Óleo y resina en tela, 40 x 240cm c_u, díptico, 2018 copy.png"},
    {original: img6, description: "6. Sin fondo, Polvo mármol negro, óleo, malla alambre, yeso y cemento sobre madera, 122 x 94 cm, 2019  copy.png"},
    {original: img7, description: "7. Fracciones, Instalación, Resina epóxica y tela,  240 x 480 cm aproximado,2019 copy.png"},
    {original: img8, description: "8. Procesos, Esmalte y oleo en tela, 140 X 180 cm.2020 copy.png"},
    {original: img9, description: "9. Nódulos, Mixta en tela, 80 x 180 cm, 2018 copy.png"},
    {original: img10, description: "10. Lagrimas. Resina epóxica, 110 x 280 cm,2019 copy.png"},
];

const Fragmentacion = () => <Galeria images={images} label={label}/>;

export default Fragmentacion;


