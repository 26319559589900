import React, {Component} from 'react';
// import './Contacto.css'
import BackgroundImg from '../img/contacto/profundidad.png'

class Contacto extends Component {
    render() {
        return (
            <div className="Contacto">
                <img src={BackgroundImg} alt="" className="ContactoBackgroundImage"/>
                <div className="ContactoForm">
                    <h1>Envía un mensaje</h1>
                    <form method="post" action="https://formspree.io/ventas@uthopia.art">
                        <p>
                            <label>Nombres *</label>
                            <input id="nombre" type="text" name="Nombre" required placeholder="Juan Pérez"/>
                        </p>
                        <p>
                            <label>Teléfono/Celular</label>
                            <input id="telefono" type="tel" name="Teléfono" placeholder="+5215529557037"/>
                        </p>
                        <p>
                            <label>Correo electrónico *</label>
                            <input id="correo" type="email" name="_replyto" required placeholder="juan@example.com"/>
                        </p>
                        <p>
                            <label>Mensaje *</label>
                            <textarea id="mensaje" name="Mensaje" required placeholder="Aquí va su mensaje"/>
                        </p>
                        <input type="hidden" name="_gotcha"/>
                        <input type="hidden" name="_next" value="https://www.leonorhochschild.com/#/contacto"/>
                        <input type="hidden" name="_subject"
                               value="Contacto - Leonor Hochschild"/>
                        <button type="submit">Enviar</button>
                    </form>
                </div>
            </div>
        );
    }
}

export default Contacto;
