import React from 'react';
import Galeria from "../../Galeria";

import label from './img/ROCAS.png'

import img1 from './img/1. Trozos de ilusión, Pigmentos, óleo, arenas, cuarzos y óxido de aluminio sobre lámina de aluminio y madera, 120 x 160 cm, 2017.png'
import img2 from './img/2. Transparencia1, Resina epóxica, 55 x 111 x 4 cm, 2019 copy.png'
import img3 from './img/3. Agua, Resina cristal y óleo, 90 x 120 cm.2020 copy.png'
import img4 from './img/4. N2. Superficie sólida, 29 x 30 x 19 cm. 2021 copy.png'
import img5 from './img/5. Entre el agua, Óleo y resina cristal, 81 x 89 x 4 cm, 2020 copy.png'
import img6 from './img/6. Formas, Resina epóxica, 92 x 56 x 4 cm, 2020 copy.png'
import img7 from './img/7. Materia orgánica, Mixta sobre cartón pluma, 35 x 50 cm, 2012 copy.png'
import img8 from './img/8. Transparencia azul1, Resina epóxica y óleo, 95 x 225 cm, 2019 copy.png'
import img9 from './img/9. Tres de tres, Óleo sobre resina cristal, 70 x 130 cm, 2020 copy.png'
import img10 from './img/10. Mb1, Superficie Sólida, 19 x 20 x 17 cm, 2020 copy.png'
import img11 from './img/11. Cristal colgante. Resina cristal. 240 x 125 x 2 cm. 2020 copy.png'
import img12 from './img/12. B19. Superficie sólida. 32 x 62 x 20  cm. 2020 copy.png'
import img13 from './img/13. Fusión, Óleo y resina en lino, 90 x 140 cm, 2015 copy.png'
import img14 from './img/14. Transparencia2, Resina Cristal Dos piezas, 133 x 100 x 14 cm cada una, 2019 copy.png'


const images = [
    {original: img1, description: "1. Trozos de ilusión, Pigmentos, óleo, arenas, cuarzos y óxido de aluminio sobre lámina de aluminio y madera, 120 x 160 cm, 2017"},
    {original: img2, description: "2. Transparencia1, Resina epóxica, 55 x 111 x 4 cm, 2019"},
    {original: img3, description: "3. Agua, Resina cristal y óleo, 90 x 120 cm.2020"},
    {original: img4, description: "4. N2. Superficie sólida, 29 x 30 x 19 cm. 2021"},
    {original: img5, description: "5. Entre el agua, Óleo y resina cristal, 81 x 89 x 4 cm, 2020"},
    {original: img6, description: "6. Formas, Resina epóxica, 92 x 56 x 4 cm, 2020"},
    {original: img7, description: "7. Materia orgánica, Mixta sobre cartón pluma, 35 x 50 cm, 2012"},
    {original: img8, description: "8. Transparencia azul1, Resina epóxica y óleo, 95 x 225 cm, 2019"},
    {original: img9, description: "9. Tres de tres, Óleo sobre resina cristal, 70 x 130 cm, 2020"},
    {original: img10, description: "10. Mb1, Superficie Sólida, 19 x 20 x 17 cm, 2020"},
    {original: img11, description: "11. Cristal colgante. Resina cristal. 240 x 125 x 2 cm. 2020"},
    {original: img12, description: "12. B19. Superficie sólida. 32 x 62 x 20  cm. 2020"},
    {original: img13, description: "13. Fusión, Óleo y resina en lino, 90 x 140 cm, 2015"},
    {original: img14, description: "14. Transparencia2, Resina Cristal Dos piezas, 133 x 100 x 14 cm cada una, 2019"},
  ];

const Rocas = () => <Galeria images={images} label={label}/>;

export default Rocas;
