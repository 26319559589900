import React from 'react';
import Galeria from "../../Galeria";

import label from './img/RESTOS.png'

import img1 from './img/1. Una caja atrapada en una mancha, Mixta sobre papel y cartón pluma, 70 x 100 cm, 2011 copy.png'
import img2 from './img/2. Arbusto, Base, Resina cristal, 30 x 50 x 5 cm, 2019.JPG copy.png'
import img3 from './img/3. Restos, Resina cristal y hojas de árbol, 20 x 20 x 9 cm, 2019 copy.png'
import img4 from './img/4. Antes que la nada, Óleo y acrílico en tela, 80 x 100 cm, 2018 copy.png'
import img5 from './img/5. Perdida, Óleo, arenas y barniz en tela, 150 x 150 cm, 2019 copy.png'
import img6 from './img/6. Esencia del espíritu, Óleo y acrílico en tela, 140 x 180 cm, 2016 copy.png'
import img7 from './img/7. En medio del abismo, Acrílico y óleo en tela, 90 x 190 cm, 2019 copy.png'

const images = [
    {original: img1, description: "1. Una caja atrapada en una mancha, Mixta sobre papel y cartón pluma, 70 x 100 cm, 2011 copy.png"},
    {original: img2, description: "2. Arbusto, Base, Resina cristal, 30 x 50 x 5 cm, 2019.JPG copy.png"},
    {original: img3, description: "3. Restos, Resina cristal y hojas de árbol, 20 x 20 x 9 cm, 2019 copy.png"},
    {original: img4, description: "4. Antes que la nada, Óleo y acrílico en tela, 80 x 100 cm, 2018 copy.png"},
    {original: img5, description: "5. Perdida, Óleo, arenas y barniz en tela, 150 x 150 cm, 2019 copy.png"},
    {original: img6, description: "6. Esencia del espíritu, Óleo y acrílico en tela, 140 x 180 cm, 2016 copy.png"},
    {original: img7, description: "7. En medio del abismo, Acrílico y óleo en tela, 90 x 190 cm, 2019 copy.png"},
  ];

const Restos = () => <Galeria images={images} label={label}/>;

export default Restos;
