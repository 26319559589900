import React from 'react';
import Galeria from "../../Galeria";

import label from './img/REFRACCION.png'

import img1 from './img/1. Aerostáticos, Esmalte, óleo y papel  sobre policarbonato, 100 x 100 cm, 2013 copy.png'
import img2 from './img/2. Prisma1, Óleo y resina sobre lámina de acrílico, 80 x 20 x 20 cm, 2016 copy.png'
import img3 from './img/3. Geomorfos. Resina cristal.130 x 74 x 2 cm.2021 copy.png'
import img4 from './img/4. Prisma3, Óleo y resina sobre lámina de acrilico, 30 x 30 x 6 cm, 2016 copy.png'
import img5 from './img/5. Prisma4, Óleo y resina sobre lámina de acrílico, 30 x 30 x 6 cm, 2016 copy.png'
import img6 from './img/6. Luz, Óleo y barniz sobre lámina de acrílico y policarbonato, 28.5 x 21 cm, 2012 copy.png'
import img7 from './img/7. Ir y venir, Óleo sobre policarbonato, 43 x 38 cm, 2014 copy.png'
import img8 from './img/8. Gotas, Instalación, Resina cristal, 356 piezas, 2 x 2 x 2 metros, 2020 copy.png'

const images = [
  {original: img1, description: "1. Aerostáticos, Esmalte, óleo y papel  sobre policarbonato, 100 x 100 cm, 2013 copy.png"},
  {original: img2, description: "2. Prisma1, Óleo y resina sobre lámina de acrílico, 80 x 20 x 20 cm, 2016 copy.png"},
  {original: img3, description: "3. Geomorfos. Resina cristal.130 x 74 x 2 cm.2021 copy.png"},
  {original: img4, description: "4. Prisma3, Óleo y resina sobre lámina de acrilico, 30 x 30 x 6 cm, 2016 copy.png"},
  {original: img5, description: "5. Prisma4, Óleo y resina sobre lámina de acrílico, 30 x 30 x 6 cm, 2016 copy.png"},
  {original: img6, description: "6. Luz, Óleo y barniz sobre lámina de acrílico y policarbonato, 28.5 x 21 cm, 2012 copy.png"},
  {original: img7, description: "7. Ir y venir, Óleo sobre policarbonato, 43 x 38 cm, 2014 copy.png"},
  {original: img8, description: "8. Gotas, Instalación, Resina cristal, 356 piezas, 2 x 2 x 2 metros, 2020 copy.png"},
];

const Refraccion = () => <Galeria images={images} label={label}/>;

export default Refraccion;
